.carousel {
  &-control-prev,
  &-control-next {
    z-index: $stretched-link-z-index + 1;

    .icon {
      color: $red;
    }
  }
  .card {
    flex-grow: 1;
    border: none;
    border-right: 1px solid $border-color;

    &-cover {
      width: 300px; // fit to image width
      margin: 0 auto;
      background-color: $white;
    }

    &:last-child {
      padding: 0;
      border: none;
    }
  }
  &-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

@include media-breakpoint-down(lg) {
  .carousel {
    &-control-prev-container,
    &-control-next-container {
      display: none;
    }

    &-item {
      position: initial;
      display: block;
      float: initial;
      margin-bottom: $spacer * 2;
    }

    .card {
      padding-right: 0;
      border: none;
    }
  }
}

.news-carousel {
  .carousel-indicators {
    position: initial;

    [data-bs-target] {
      background-color: $red;
    }
  }
  @include media-breakpoint-up(lg) {
    border-right: solid 2px $dark;
  }
}
