.footer {
  a {
    color: $white;
  }
  padding: 1.5rem 1.5rem 4rem;
  &-address p {
    margin-bottom: 0.25rem;
  }
  &-icon-link {
    margin-right: $spacer;
    color: $white;
    text-decoration: none;
    &:hover {
      color: $black;
    }
  }
}
