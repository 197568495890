$btn-zindex: $stretched-link-z-index + 1;

// button with svg icon inside
.btn-svg {
  padding: 0;
  &-primary {
    color: $primary;
    &:hover {
      color: $btn-link-hover-color;
    }
  }
}

.btn-hover-link {
  position: relative;
  z-index: $btn-zindex;
}
