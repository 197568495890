$sizes: (
  'small': 1em,
  'medium': 2em,
  'big': 3em,
);

.icon {
  display: inline-block;
  fill: currentColor;

  @each $sizename, $size in $sizes {
    &-#{$sizename} {
      width: $size;
      height: $size;
    }
  }

  &-btn-horizontal {
    width: (2 * $input-btn-padding-y + $btn-line-height) * 2; // height * 2
    height: 2 * $input-btn-padding-y + $btn-line-height;
  }
}
