@mixin header-transition($prop) {
  transition: $prop 180ms ease-in-out;
}

.navbar {
  &-border {
    border-bottom: 1px solid $border-color;
  }
  &-nav {
    margin: 1.5rem 1rem 1.5rem 0;
    @include header-transition(margin);
    .header-scrolling & {
      margin: 0;
    }
  }
}

.logo-img {
  width: auto;
  height: 157px;
  object-fit: contain;
  @include header-transition(height);
  .header-scrolling & {
    height: 50px;
  }
}

.header-support {
  padding-left: 1.5rem;
  @include media-breakpoint-up(lg) {
    border-left: 2px solid $dark;
    @include header-transition(border-left);
    .header-scrolling & {
      border-left: 1px solid $border-color;
    }
  }
}

.icon {
  svg {
    vertical-align: baseline;
  }
  &-gray {
    fill: $gray-600;
    stroke: $gray-600;
  }
}

.searchbar {
  @include media-breakpoint-down(sm) {
    input {
      width: 80%;
    }
  }
}
