// -----------------------------------------------------------------------------
// Application-wide variables
// -----------------------------------------------------------------------------

// Path to fonts and images folders, relative to app.scss
$font-path: '../fonts';
$img-path: '../img';

//
// Bootstrap
//
// see: ../../node_modules/bootstrap/scss/_variables.scss

// Color system
// You could generate a color scheme with: https://palx.jxnblk.com

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #bc422e;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

// Bootstrap colors
$primary: $red;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $orange;
$light: $gray-100;
$dark: $gray-900;

$body-bg: $light;

// Options

$enable-validation-icons: false;

// navbar
$navbar-light-color: $black;
$navbar-padding-y: 0;

// Typography

$font-family-sans-serif: 'Roboto';
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$headings-font-family: 'Roboto Condensed';
$headings-font-weight: $font-weight-bold;
$headings-margin-bottom: 1rem;

$link-color: $black;
$link-hover-color: $primary;

// buttons
$input-btn-focus-width: 0;
$input-btn-padding-y: 0.375rem;
$btn-border-radius: 1.5rem;
$btn-line-height: 1.5;

// borders
$border-color: $gray-300;

//cards
$card-bg: transparent;

// carousel
$carousel-control-width: none;
