// -----------------------------------------------------------------------------
// Font faces declarations
// -----------------------------------------------------------------------------

@use '@fontsource/roboto/scss/mixins' as Roboto;
@use '@fontsource/roboto-condensed/scss/mixins' as RobotoCondensed;

$font-weights: ($font-weight-light, $font-weight-normal, $font-weight-bold);

@each $font-weight in $font-weights {
  @include RobotoCondensed.fontFace(
    $fontName: $headings-font-family,
    $weight: $font-weight
  );
  @include RobotoCondensed.fontFace(
    $fontName: $headings-font-family,
    $weight: $font-weight,
    $style: italic
  );
}

@each $font-weight in $font-weights {
  @include Roboto.fontFace(
    $fontName: $font-family-sans-serif,
    $weight: $font-weight
  );
}

$font-family-base: $font-family-sans-serif;
